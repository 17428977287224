<div class="lookup-container">
  <h1>Register</h1>
  <label for="personal-info">Personal Information</label>
  <form [formGroup]="form" id="personal-info">
    <mat-form-field appearance="outline" class="multi-errors">
      <mat-label>First Name</mat-label>
      <input
        matInput
        required
        maxlength="255"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="false"
        formControlName="firstName"
      />
      <mat-error *ngIf="form.get('firstName')?.hasError('noAsterisk')">
        No axterix
      </mat-error>
      <mat-error *ngIf="form.get('firstName')?.hasError('whitespace')">
        No whitespace
      </mat-error>
      <mat-error *ngIf="form.get('firstName')?.hasError('maxlength')">
        To much text
      </mat-error>
      <mat-error *ngIf="form.get('firstName')?.hasError('required')">
        Field is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="multi-errors">
      <mat-label>Last Name</mat-label>
      <input
        matInput
        required
        maxlength="255"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="false"
        formControlName="lastName"
      />
      <mat-error *ngIf="form.get('lastName')?.hasError('noAsterisk')">
        No axterix
      </mat-error>
      <mat-error *ngIf="form.get('lastName')?.hasError('whitespace')">
        No whitespace
      </mat-error>
      <mat-error *ngIf="form.get('lastName')?.hasError('maxlength')">
        To much text
      </mat-error>
      <mat-error *ngIf="form.get('lastName')?.hasError('required')">
        Field is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-field multi-errors">
      <mat-label>Date of birth</mat-label>
      <input
        required
        matInput
        autocomplete="off"
        autocapitalize="off"
        spellcheck="false"
        [matDatepicker]="picker"
        [max]="dateSettings.maxDate"
        [min]="dateSettings.minDate"
        formControlName="birthDate"/>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        [startAt]="pickedDate ? pickedDate : dateSettings.startDate"
        #picker
      ></mat-datepicker>
      <mat-error *ngIf="form.get('birthDate')?.hasError('matDatepickerParse')">
        Wrong date format
      </mat-error>
      <mat-error *ngIf="form.get('birthDate')?.hasError('required')">
        Field is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label class="label">
        Gender
      </mat-label>
      <input matInput hidden required />
      <mat-radio-group
        class="gender-container"
        labelPosition="after"
        formControlName="genderKey"
        required
      >
        <mat-radio-button
          color="primary"
          *ngFor="let gender of genders | keyvalue"
          [value]="gender.key"
          class="radio-button"
        >
          {{ gender.value }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-form-field>
   <!-- <mat-form-field appearance="outline" floatLabel="always">
      <mat-label class="label">Personal SSN identification</mat-label>
      <input matInput hidden required />
      <mat-radio-group
        fxLayout="column"
        labelPosition="after"
        formControlName="personIdTypeKey"
        required
      >
        <mat-radio-button
          class="radio-button"
          *ngFor="let identifier of identifierMap | keyvalue"
          [value]="identifier.key"
        >
          {{ identifier.value | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </mat-form-field>-->
    <mat-form-field appearance="outline">
      <mat-label class="label">
        Last 5 digits of SSN.
      </mat-label>
      <input
        matInput
        required
        maxlength="5"
        pattern="[0-9]{5}"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="false"
        formControlName="personId"
      />
      <mat-error *ngIf="form.get('personId')?.hasError('pattern')">
        Only digits allowed
      </mat-error>
      <mat-error *ngIf="form.get('personId')?.hasError('required')">
        Field is required
      </mat-error>
    </mat-form-field>
  </form>
  <button mat-raised-button color="primary"
          mat-dialog-close
          (click)="openPreferences()">Register Now</button>
</div>
