<div class="leaderboard-container">
  <div class="profile-donations">
    <div class="profile-picture">
      <img
        src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1609440126601x582624171776663600%2Favatar-male.png?w=64&h=64&auto=compress&fit=crop&dpr=1"
        alt="">
      <span>Adam</span>
    </div>
    <div class="settings" (click)="openSettingsDialog()">
      <h4 id="title">{{ goalDonation }} Donations</h4>
      <span>your monthly goal</span>
      <div>

        <ul>
          <mat-icon>wb_sunny</mat-icon>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

      </div>
      <div class="slider-settings">
        <div class="slider-settings-content">
          <h4>{{ donationsPercent }}%</h4>
          <span>of goal</span>
        </div>
        <div class="slider-settings-content">
          <h4>{{ donationsToGo }}</h4>
          <span>donations to go</span>
        </div>
      </div>
    </div>
    <div class="octa-pass-login-container" (click)="openLink('https://donor360.octapharma.com/login')">
      <mat-icon>qr_code</mat-icon>
      <img
        src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1609078041434x130764974070732620%2FOctaPass-Logo-White.png?w=192&h=38&auto=compress&fit=crop&dpr=1">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </div>
  <div class="statistics-container">
    <div class="statistic-content" (click)="openMapDirectionDialog()">
      <mat-icon style="color: rgb(0, 75, 149)">whatshot</mat-icon>
      <div class="statistic-part">
        <h4 style="color: rgb(0, 75, 149)">Last Donation</h4>
        <span>Jan 04</span>
      </div>
      <div class="statistic-part">
        <h4 style="color: rgb(0, 75, 149)">Next Donation</h4>
        <span class="bold-text">Jan 07</span>
      </div>
      <mat-icon class="arrow-color">keyboard_arrow_right</mat-icon>
    </div>
    <div class="statistic-content" (click)="openPaymentsDialog()">
      <mat-icon style="color: rgb(39, 174, 96)">credit_card</mat-icon>
      <div class="statistic-part">
        <h4 style="color: rgb(39, 174, 96)">Card Balance</h4>
        <span>$107.11</span>
      </div>
      <div class="statistic-part">
        <h4 style="color: rgb(39, 174, 96)">Next Payment</h4>
        <span class="bold-text">$35</span>
      </div>
      <mat-icon class="arrow-color">keyboard_arrow_right</mat-icon>
    </div>
    <div class="statistic-content" (click)="openLink('https://octapharma.nextbee.com/index.html')">
      <mat-icon style="color: rgb(232, 160, 83)">card_giftcard</mat-icon>
      <div class="statistic-part">
        <h4 style="color: rgb(232, 160, 83)">Last Reward</h4>
        <span>6,500</span>
      </div>
      <div class="statistic-part">
        <h4 style="color: rgb(232, 160, 83)">Next Reward</h4>
        <span class="bold-text">500</span>
      </div>
      <mat-icon class="arrow-color">keyboard_arrow_right</mat-icon>
    </div>
    <div class="statistic-content" (click)="openLink('https://octapharmaplasma.com/donor/donor-referral/referralLink')">
      <mat-icon style="color: rgb(235, 87, 87)">group</mat-icon>
      <div class="statistic-part">
        <h4 style="color: rgb(235, 87, 87)">Referrals</h4>
        <span>Jan 04</span>
      </div>
      <div class="statistic-part">
        <h4 style="color: rgb(235, 87, 87)">Registered</h4>
        <span class="bold-text">Jan 07</span>
      </div>
      <mat-icon class="arrow-color">keyboard_arrow_right</mat-icon>
    </div>
  </div>
</div>
