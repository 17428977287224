import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OnBoardingModel} from '../../../models/on-boarding.model';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.scss']
})
export class OnBoardingComponent implements OnInit {
  onBoardingOrder = 0;
  model: OnBoardingModel;

  @Output()
  finishOnBoarding: EventEmitter<void> = new EventEmitter<void>();

  private models: OnBoardingModel[] = [
    {
      title: 'Next Donation',
      img: 'bloodtype',
      description: 'Find next eligible date for your donation and how much you will be earning',
      order: 0,
      button: '',
      permission: ''
    },
    {
      title: 'Refer & Earn',
      img: 'people',
      description: 'Invite your friends and family and get instant cash reward to your account',
      order: 1,
      button: '',
      permission: ''
    },
    {
      title: 'Find Centers',
      img: 'location_on',
      description: 'Allow app to use your location to show nearby donation centers',
      order: 2,
      button: 'Allow location',
      permission: 'Allow Location While Using App'
    },
    {
      title: 'Notifications',
      img: 'notifications',
      description: 'Enable Push Notification to help you stay on top of plasma donations',
      order: 3,
      button: 'Enable Notifications',
      permission: 'Skip For Now'
    }
  ];

  constructor() {
  }

  ngOnInit(): void {
    this.model = this.models[this.onBoardingOrder];
  }

  swipeLeft() {
    if (this.onBoardingOrder < 3) {
      this.onBoardingOrder = this.onBoardingOrder + 1;
      this.model = this.models[this.onBoardingOrder];
    } else {
      this.finishOnBoarding.emit();
    }
  }

  swipeRight() {
    if (this.onBoardingOrder > 0) {
      this.onBoardingOrder = this.onBoardingOrder - 1;
      this.model = this.models[this.onBoardingOrder];
    }
  }

  allowPermission(permission: string): void {
    if (!permission.includes('Location')) {
      Notification.requestPermission().then(() => {
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        () => {
        },
        () => {
        });
    }
  }

  skip(permission: string): void {
    if (permission.includes('Skip')) {
      this.finishOnBoarding.emit();
    }
  }

  clicked(position: string) {
    if (position === 'left') {
      this.swipeRight();
    } else {
      this.swipeLeft()  ;
    }
  }
}
