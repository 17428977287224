<div class="home-container">
  <div class="banner-container" *ngIf="!appState.isLoggedIn">
    <div class="banner-content">
      <div>
        <p>Welcome to</p>
        <img src="https://dd7tel2830j4w.cloudfront.net/f1608998700368x236377852183108130/octapharma-ag-logo-white.svg">
      </div>
      <button mat-raised-button (click)="openLoginDialog()">SIGN UP OR LOGIN</button>
      <p class="banner-text">Plasma makes it possible.</p>
    </div>
  </div>
  <div class="earnings-information-container">
    <h1>Donate & Earn</h1>
    <div class="help">
      <div class="earnings-information-content">
        <p>Your potential earnings</p>
        <p class="earnings-text">${{ money * 35 }}</p>
      </div>
      <div class="earnings-information-content">
        <p>Calculate your potential earnings</p>

        <div class="slider">
          <div class="slider-label">
            <span style="visibility: hidden">0</span>
            <span [style.font-weight]="money == 1 ? 'bold' : ''">1</span>
            <span [style.font-weight]="money == 2 ? 'bold' : ''">2</span>
            <div class="slider-label-img">
              <span [style.font-weight]="money == 3 ? 'bold' : ''">3</span>
              <mat-icon *ngIf="firstLabel">wb_sunny</mat-icon>
              <span
                class="slider-bonus"
                *ngIf="firstLabel">Bonus!</span>
            </div>
            <span [style.font-weight]="money == 4 ? 'bold' : ''">4</span>
            <span [style.font-weight]="money == 5 ? 'bold' : ''">5</span>
            <span [style.font-weight]="money == 6 ? 'bold' : ''">6</span>
            <span [style.font-weight]="money == 7 ? 'bold' : ''">7</span>
            <div class="slider-label-img">
              <span [style.font-weight]="money == 8 ? 'bold' : ''">8</span>
              <mat-icon *ngIf="secondLabel">wb_sunny</mat-icon>
              <span
                class="slider-bonus"
                *ngIf="secondLabel">Bonus!</span>
            </div>
          </div>
          <input class="slider-range" value="0" type="range" max="8" min="0" step="1" [(ngModel)]="money"
                 (ngModelChange)="sliderChange()">
        </div>

        <p class="slider-description">How many donations per month?</p>
      </div>
    </div>
  </div>
  <div class="find-centre-container">
    <p>Find an Octapharma Donation Center</p>
    <div class="find-center-form">
      <mat-form-field appearance="outline">
        <mat-label>Find donation center</mat-label>
        <input matInput
               type="number"
               placeholder="Enter Zip"
               required
               #zipCode="ngModel"
               ngModel>
        <mat-error *ngIf="zipCode.invalid"></mat-error>
      </mat-form-field>
      <button (click)="openMapDialog(zipCode.value)" mat-raised-button>FIND CENTERS</button>
    </div>
  </div>
  <div class="news-container">
    <h3 style="color: red">Top news</h3>
    <a href="https://octapharmaplasma.com/donor/octapass">
      <div class="link-container">
        <p><strong>OctaPass</strong> is a new web application that allows returning plasma donors to complete the
          health...</p>
        <div class="link-content">
          <img
            src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1609078041434x130764974070732620%2FOctaPass-Logo-White.png?w=96&h=25&auto=compress&fit=crop&dpr=2">
        </div>
      </div>
    </a>
    <a href="https://octapharmaplasma.com/donor/covid19-donor-request">
      <div class="link-container">
        <p>Plasma is URGENTLY needed! Your safety is our top priority!.</p>
        <div class="link-content">
          <span>Donate for COVID-19</span>
        </div>
      </div>
    </a>
    <hr>
    <h3 style="color: var(--lightColorOrange)">Promotions</h3>
    <a href="https://octapharmaplasma.com/donor/promotions">
      <div class="link-container">
        <p><strong>Make over $700 during December!</strong> Plus, COVID donors earn more.</p>
        <div class="link-content">
          <span>Sign Up</span>
          <span>or</span>
          <span>Login</span>
        </div>
      </div>
    </a>
  </div>
  <div class="donor-information-container">
    <h1>Donor Information</h1>
    <p>You’ve taken the first step to becoming a plasma donor by learning more. We’ll do everything possible to walk you
      through the process and make your first-time plasma donation comfortable, easy, and rewarding.</p>
    <a href="https://octapharmaplasma.com/donor/first-time-donors">First-Time Donors</a>
    <a href="https://octapharmaplasma.com/donor/payment-rewards">Payments & Rewards</a>
    <a href="https://octapharmaplasma.com/donor/health-nutrition">Health & Nutrition Tips</a>
    <a href="https://octapharmaplasma.com/donor/patient-donor-stories">Patient & Donor Stories</a>
    <a href="https://octapharmaplasma.com/donor/plasma-donation-faq">Plasma Donation FAQs</a>
  </div>
  <div class="footer-container">
    <div style="margin-right: 20%">
      <h3 style="color: var(--lightColorBlue) ">Octopharma Corp.</h3>
      <p>Contact us</p>
      <p>EULA</p>
    </div>
    <div>
      <h3 style="color: var(--lightColorOrange)">Join us on social</h3>
      <p>Facebook</p>
      <p>Instagram</p>
      <p>Twitter</p>
    </div>
  </div>
</div>
