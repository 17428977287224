<div class="lookup-preferences-container">
  <h1>Register</h1>
  <span>Preferences and Terms</span>
  <app-preference *ngFor="let preference of preferences"
                  [preference]="preference">
  </app-preference>
  <a href="#">App Terms & Conditions</a>
  <a href="#">Loyalty Terms</a>
  <button mat-raised-button (click)="register()" mat-dialog-close>Register Now</button>
</div>
