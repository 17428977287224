import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import {AppStateService} from './app-state.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationsComponent} from './components/dialogs/notifications/notifications.component';
import {OnBoardingModel} from './models/on-boarding.model';

const isAlreadyOnBoardKey = 'isAlreadyOnBoard'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('drawer')
  drawer: MatSidenav;
  isLoading = true;
  onBoarding: OnBoardingModel
  shouldOnBoard: boolean;

  constructor(private router: Router, public appState: AppStateService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (!localStorage.getItem(isAlreadyOnBoardKey)) {
      this.setOnBoarding(true);
    }
  }

  openDrawerLink(path: string) {
    this.router.navigate([path]);
    this.drawer.close();

    if (!path) {
      this.appState.isLoggedIn = false;
      this.router.navigate(['']);
    }
  }

  openNotificationsDialog() {
    this.dialog.open(NotificationsComponent, {minWidth: 340, maxHeight: 500, backdropClass: 'dialog-backdrop'})
  }

  loadingEnded(): void {
    this.isLoading = false;
  }

  setOnBoarding(onBoardStatus: boolean): void {
    this.shouldOnBoard = onBoardStatus;
    if (!onBoardStatus) {
      localStorage.setItem(isAlreadyOnBoardKey, 'true');
    }
  }

  clearLocalStorage() {
    this.isLoading = true;
    localStorage.clear();
    this.setOnBoarding(true);
    this.router.navigate(['']);
    this.appState.isLoggedIn = false;
  }
}
