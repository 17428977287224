<div class="donation-center-map-container">
  <h1>Last Donation</h1>
  <h4>Octapharma Charlotte - South</h4>
  <a href="https://www.google.com/maps/place/Octapharma+Plasma/@35.207568,-80.9221089,12z/data=!3m1!5s0x88569e9542d74dd9:0xb24c18c344bcb885!4m8!1m2!2m1!1soctapharma+charlotte+south!3m4!1s0x88569e956b2a4ed1:0x32588164db54dfb4!8m2!3d35.154308!4d-80.875152">
    <div class="map-link">
      <mat-icon>place</mat-icon>
      <span>5943 South Boulevard, Charlotte, NC 28217</span>
    </div>
  </a>
  <a href="https://www.google.com/maps/dir//Octapharma+Plasma,+5943+South+Blvd,+Charlotte,+NC+28217,+United+States/@35.207568,-80.9221089,12z/data=!3m1!5s0x88569e9542d74dd9:0xb24c18c344bcb885!4m8!4m7!1m0!1m5!1m1!1s0x88569e956b2a4ed1:0x32588164db54dfb4!2m2!1d-80.875152!2d35.154308">
    <div class="map-link">
      <mat-icon>directions</mat-icon>
      <span>Directions</span>
    </div>
  </a>
  <div class="map-input">
    <mat-form-field appearance="outline">
      <mat-label>Find A Center</mat-label>
      <input
        matInput
        ngModel
        #address="ngModel"
        type="text"
        placeholder="Charlotte, NC 28217, USA"
        required>
    </mat-form-field>
    <button mat-raised-button (click)="findAddress(address.value)">Find</button>
  </div>
  <iframe
    [src]="googleMapLink"
    width="318"
    height="230"
    style="border:0;"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0">
  </iframe>
  <a id="link" href="https://www.google.com/maps/search/?api=1&query=octapharma+plasma+in+28217">Find on Maps</a>
  <button mat-raised-button mat-dialog-close>Close</button>
</div>
