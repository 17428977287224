<div class="payments-container">
  <h1>Last 5 Payments</h1>
  <div class="payments-list">
    <div class="payments-list-content">
      <h3>Date</h3>
      <span>Dec 20</span>
      <span>Dec 24</span>
      <span>Dec 27</span>
      <span>Dec 31</span>
      <span>Jan 04</span>
    </div>
    <div class="payments-list-content">
      <h3>Payments</h3>
      <span>$35</span>
      <span>$35</span>
      <span>$35</span>
      <span>$35</span>
      <span>$35</span>
    </div>
  </div>
  <p>Contact North Lane (formerly Wirecard) for any of the following inquiries:</p>
  <ul>
    <li>Activate or use your card</li>
    <li>Check your balance</li>
    <li>Replace a lost or stolen card</li>
  </ul>
  <div class="payments-contact">
    <div class="payments-contact-content">
      <mat-icon>phone</mat-icon>
      <span>1 (866) 326-8689</span>
    </div>
    <div class="payments-contact-content">
      <mat-icon>directions</mat-icon>
      <span>North Lane Online</span>
    </div>
  </div>
  <button mat-raised-button matDialogClose>Close</button>
</div>
