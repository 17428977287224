import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {PaymentsComponent} from "../dialogs/payments/payments.component";
import {DonationCenterMapComponent} from "../dialogs/donation-center-map/donation-center-map.component";
import {SettingsComponent} from "../dialogs/settings/settings.component";

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  constructor(private dialog: MatDialog) { }
  value: number = 0;

  donationsToGo = 0;
  donationsPercent = 0;
  goalDonation = 0;


  ngOnInit(): void {
  }

  openPaymentsDialog() {
    this.dialog.open(PaymentsComponent, { maxWidth: 340, backdropClass: 'dialog-backdrop' })
  }

  openMapDirectionDialog() {
    this.dialog.open(DonationCenterMapComponent, { maxWidth: 340, maxHeight: 600, backdropClass: 'dialog-backdrop' })
  }

  openSettingsDialog() {
    this.dialog.open(SettingsComponent, { minWidth: 360, backdropClass: 'dialog-backdrop'})
      .afterClosed().subscribe(data => {
        this.donationsToGo = data;
        this.goalDonation = data;
      })
  }

  openLink(link: string) {
    window.open(link)
  }
}
