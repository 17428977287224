<div class="app-container">
  <mat-drawer-container *ngIf="!isLoading && !shouldOnBoard">
    <mat-drawer #drawer mode="over">
      <div class="drawer-content">
        <span (click)="openDrawerLink('preferences')">Preferences</span>
        <span (click)="openDrawerLink('contact-us')">Contact Us</span>
        <span (click)="openDrawerLink('eula')">EULA</span>
        <span (click)="openDrawerLink('')" *ngIf="appState.isLoggedIn">Logout</span>
        <span (click)="clearLocalStorage()">Reset</span>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="main-content" [ngStyle]="{'padding-bottom': appState.isLoggedIn ? '35px' : 0}">
        <mat-toolbar class="toolbar-container" *ngIf="!isLoading && !shouldOnBoard">
          <button mat-icon-button (click)="drawer.opened ? drawer.close() :drawer.open()">
            <mat-icon>menu</mat-icon>
          </button>
          <span class="toolbar-logo" *ngIf="appState.isLoggedIn">
            <img src="https://dd7tel2830j4w.cloudfront.net/f1608998700368x236377852183108130/octapharma-ag-logo-white.svg"
              alt="Octapharma Logo">
          </span>
          <!--  <button mat-icon-button  (click)="openNotificationsDialog()" *ngIf="appState.isLoggedIn">
              <mat-icon matBadge="2" matBadgeColor="warn" matBadgeSize="medium">notifications_outline</mat-icon>
            </button>-->
        </mat-toolbar>
        <router-outlet></router-outlet>
        <footer *ngIf="!isLoading && !shouldOnBoard">
          <span>© 2021 Haemonetics Corporation</span>
        </footer>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  <app-loading *ngIf="isLoading" (loading)="loadingEnded()"></app-loading>
  <ng-container *ngIf="!isLoading">
    <app-on-boarding
      (finishOnBoarding)="setOnBoarding(false)"
      *ngIf="shouldOnBoard">
    </app-on-boarding>
  </ng-container>
</div>
<div style="display: flex; justify-content: center">
  <div class="footer-menu-container" *ngIf="appState.isLoggedIn">
    <div class="footer-menu-content" routerLink="">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </div>
    <div class="footer-menu-content" routerLink="/leaderboard">
      <mat-icon>leaderboard</mat-icon>
      <span>Leaderboard</span>
    </div>
    <div class="footer-menu-content" routerLink="/promotions">
      <mat-icon>label</mat-icon>
      <span>Promotions</span>
    </div>
    <div class="footer-menu-content" routerLink="/social">
      <mat-icon>alternate_email</mat-icon>
      <span>Social</span>
    </div>
  </div>
</div>

