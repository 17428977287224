import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {LookupPreferencesComponent} from '../preferences/lookup-preferences.component';
import {Route, Router} from '@angular/router';
import {AppStateService} from '../../app-state.service';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {
  genders = {f: 'Female', m: 'Male'};
  form: FormGroup;

  public dateSettings = {
    maxDate: new Date(),
    startDate: new Date(1990, 0, 1),
    minDate: new Date(1901, 0, 1),
  };
  pickedDate = null;

  constructor(private builder: FormBuilder, private dialog: MatDialog, private router: Router, private appState: AppStateService) {
    this.form = this.builder.group({
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      birthDate: new FormControl('', Validators.required),
      personIdTypeKey: new FormControl('', Validators.required),
      personId: new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9]{5}'),
      ]),
      genderKey: new FormControl('', Validators.required),
    });

    this.form.get('birthDate')?.valueChanges.subscribe((data) => {
      this.pickedDate = data;
    });
  }

  ngOnInit(): void {
  }

  openPreferences() {
    //this.dialog.open(LookupPreferencesComponent, { maxWidth: 340, hasBackdrop: false });
    this.router.navigate(['leaderboard']);
    this.appState.isLoggedIn = true;
  }
}
