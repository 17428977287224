<div class="contact-us-container">
  <h1>Contact us</h1>
  <h3>Do you have any questions?</h3>
  <div class="faq-container">
    <span><mat-icon>public</mat-icon><a href="https://octapharmaplasma.com/donor/plasma-donation-faq">Plasma donations FAQs</a></span>
    <p>Here are the answers to your most Frequently Asked Questions.</p>
  </div>
  <h3>Contact a Staff</h3>
  <div class="form-container">
    <input placeholder="First Name" type="text">
    <input placeholder="Last Name" type="text">
  </div>
  <div class="form-container">
    <input placeholder="Email" type="email">
    <input placeholder="Phone" type="tel">
  </div>
  <div class="donor-question-container">
    <h3>Current Donor?</h3>
    <mat-radio-group>
      <mat-radio-button color="primary" value="1">Yes</mat-radio-button>
      <mat-radio-button color="primary" value="2">No</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Reason for contact</mat-label>
      <mat-select>
        <mat-option value="option1">Question</mat-option>
        <mat-option value="option2">Compliment</mat-option>
        <mat-option value="option3">Feedback</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="textarea-container">
      <mat-form-field appearance="outline">
        <textarea placeholder="Type here..." rows="10" matInput></textarea>
      </mat-form-field>
      <button mat-raised-button>Send</button>
    </div>
  </div>
  <h3>More Ways to Connect</h3>
  <div class="faq-container">
    <span><mat-icon>phone</mat-icon><a href="tel:1 (704) 654-4970">1 (704) 654-4970</a></span>
    <p>Completing the Donor feedback form is the most efficient way to get a prompt response. If you prefer to leave a
      voicemail message on the Donor Relations phone line, please include your question or concern, your full name,
      donor ID, center location, and contact information.</p>
  </div>
  <div class="faq-container">
    <span><mat-icon>email</mat-icon><a
      href="mailto: contact@octapharmaplasma.com">contact@octapharmaplasma.com</a></span>
  </div>
</div>
