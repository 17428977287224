<div class="preferences-container">
  <h1>Preferences</h1>
  <div>
    <h3>Language</h3>
    <mat-radio-group>
      <mat-radio-button color="primary" value="1">English</mat-radio-button>
      <mat-radio-button color="primary" value="2">Spanish</mat-radio-button>
    </mat-radio-group>
  </div>
  <h3>Communications</h3>
  <div class="communication-container">
    <div class="communications-titles">
      <span>Octopharma news</span>
      <span>Promotions</span>
      <span>Eligibility Reminders</span>
    </div>
    <div class="check-box-container">
      <div class="check-box-content">
        <span>Push</span>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
      </div>
      <div class="check-box-content">
        <span>E-Mail</span>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
      </div>
      <div class="check-box-content">
        <span>SMS</span>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
        <mat-checkbox color="primary"></mat-checkbox>
      </div>
    </div>
  </div>
  <h3>Logins</h3>
  <div class="logins-container" routerLink="">
    <div class="logout-link">
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/1200px-Facebook_icon_2013.svg.png">
      <span>Logout</span>
    </div>
    <div class="logout-link" routerLink="">
      <img src="https://cdn4.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2/128/social-square-google-plus1-2-512.png">
      <span>Logout</span>
    </div>
  </div>
</div>

