<div class="login-container" *ngIf="isLoginState">
  <h1>Sign In</h1>
  <button class="social-button" style="background-color: #4285f4">
    <img src="assets/google_icon.svg" alt="google" style="background-color: white"/>
    Sign In wth Google
  </button>
  <button style="background-color: #4267b2" class="social-button">
    <img src="assets/facebook_icon.svg" alt="facebook"/>
    Sign In wth Facebook
  </button>
  <span class="text-with-line">
    <span>or</span>
  </span>
  <label for="email">Email Address *</label>
  <input
    class="input-amplify"
    id="email"
    ngModel
    required
    #email="ngModel"
    type="email"
    placeholder="Enter your email address"
    autofocus>
  <label for="password">Password *</label>
  <input
    class="input-amplify"
    id="password"
    ngModel
    #password="ngModel"
    type="password"
    placeholder="Enter your password"
    required>
  <span>Forgot your password?
    <span class="link" (click)="openResetPassword()">Reset password</span>
  </span>
  <button class="accept-button"
          mat-raised-button
          (click)="login()"
          routerLink="/leaderboard"
          mat-dialog-close>SIGN IN
  </button>
  <span>No account?
    <span class="link" (click)="openRegistration()">Create account</span>
  </span>
</div>
<!--
REGISTRATION FLOW
-->
<div class="login-container" *ngIf="isRegisterState">
  <h1>Create an account</h1>
  <label for="email-register">Email Address *</label>
  <input
    class="input-amplify"
    id="email-register"
    ngModel
    #register="ngModel"
    type="email"
    placeholder="Enter your email"
    required>
  <label for="password-register">Password *</label>
  <input
    class="input-amplify"
    id="password-register"
    ngModel
    #password="ngModel"
    type="password"
    placeholder="Enter your password"
    required>
  <button class="accept-button"
          mat-raised-button
          (click)="openCodeVerification(register.value)">
    CREATE AN ACCOUNT
  </button>
  <span>Have an account?
    <span class="link" (click)="openLogin()">Sign In</span>
  </span>
</div>
<!--
CODE VALIDATION
-->
<div class="login-container" *ngIf="isCodeVerification">
  <h1>Confirm Sign Up</h1>
  <label for="email-code-verification">Email Address *</label>
  <input
    class="input-amplify"
    id="email-code-verification"
    [(ngModel)]="registerEmail"
    type="text"
    disabled
    placeholder="Enter your Email Address"
    required>
  <label for="code-verification">Confirmation Code</label>
  <input
    class="input-amplify"
    id="code-verification"
    type="number"
    placeholder="Enter your code">
  <span>Lost your code?
    <span class="link">Resend code</span>
  </span>
  <button class="accept-button"
          mat-raised-button
          (click)="register()"
          mat-dialog-close>SEND CODE
  </button>
  <span class="link" (click)="openLogin()">Back To Sign In</span>
</div>
<!--
RESET PASSWORD FLOW
-->
<div class="login-container" *ngIf="isPasswordResetState">
  <h1>Forgot Your Password?</h1>
  <label for="password-reset">Email Address *</label>
  <input
    class="input-amplify"
    id="password-reset"
    type="text"
    placeholder="Enter your Email Address"
    required>
  <button class="accept-button"
          mat-raised-button
          mat-dialog-close>SEND CODE
  </button>
  <span class="link" (click)="openLogin()">Back To Sign In</span>
</div>
