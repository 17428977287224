import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AppStateService} from "../../../app-state.service";
import {Router} from '@angular/router';
import {LookupComponent} from '../../lookup/lookup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoginState = true;
  isRegisterState = false;
  isPasswordResetState = false;
  isCodeVerification = false;
  registerEmail: string;

  constructor(private dialog: MatDialog, private appState: AppStateService, private router: Router) { }

  ngOnInit(): void {
  }

  openRegistration() {
    this.isRegisterState = true;
    this.isLoginState = false;
    this.isPasswordResetState = false;
    this.isCodeVerification = false;
  }

  openResetPassword() {
    this.isRegisterState = false;
    this.isLoginState = false;
    this.isCodeVerification = false;
    this.isPasswordResetState = true;
  }

  openLogin() {
    this.isRegisterState = false;
    this.isLoginState = true;
    this.isPasswordResetState = false;
    this.isCodeVerification = false;
  }

  openCodeVerification(email: string) {
    this.isCodeVerification = true;
    this.isRegisterState = false;
    this.isLoginState = false;
    this.isPasswordResetState = false;
    this.registerEmail = email;
  }

  login() {
    this.appState.isLoggedIn = true;
  }

  register() {
    this.dialog.open(LookupComponent, { maxWidth: 340, hasBackdrop: false });
    //this.router.navigate(['lookup']);
  }
}
