import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  searchDonationCenterLink: SafeUrl;
  private link = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyB2KKeOfccpWAS0FwoPsKMrdrpBVUnXaOo&q=octapharma+plasma+in+';

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.searchDonationCenterLink = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.link}${encodeURI(this.data)}`);
    console.log(this.searchDonationCenterLink);
  }

}
