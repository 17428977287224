import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-donation-center-map',
  templateUrl: './donation-center-map.component.html',
  styleUrls: ['./donation-center-map.component.scss']
})
export class DonationCenterMapComponent implements OnInit {
  private link: string = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyB2KKeOfccpWAS0FwoPsKMrdrpBVUnXaOo&q='
  googleMapLink: SafeUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.googleMapLink = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.link}${encodeURI('5943 South Boulevard, Charlotte, NC 28217')}`);

  }

  findAddress(value: string) {
    this.googleMapLink = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.link}${encodeURI(value)}`)
    console.log(this.googleMapLink)
  }
}
