<div class="promotion-container">
  <div class="promotion-header" [ngStyle]="{'background-color': promotion.backgroundColor}">
    <div class="promotion-header-content">
      <span>{{ promotion.title }}</span>
      <span id="title">{{ promotion.subtitle }}</span>
      <span id="date">{{ promotion.date }}</span>
    </div>
    <div class="promotion-header-side">
      <span [ngStyle]="{'color': promotion.dateColor}">DEC</span>
    </div>
  </div>
  <div class="promotion-content">
    <h4>{{ promotion.contentTitle }}</h4>
    <p>{{ promotion.content }}</p>
    <button mat-raised-button
            [ngStyle]="{'background-color': promotion.backgroundColor}">Start</button>
  </div>
</div>
