import {BrowserModule, HammerModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { HomeComponent } from './components/home/home.component';
import {MatSliderModule} from "@angular/material/slider";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from "@angular/material/badge";
import {MatSidenavModule} from "@angular/material/sidenav";
import { PreferencesComponent } from './components/drawer-components/preferences/preferences.component';
import { EulaComponent } from './components/drawer-components/eula/eula.component';
import { ContactUsComponent } from './components/drawer-components/contact-us/contact-us.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from '@angular/material/select';
import { LoginComponent } from './components/dialogs/login/login.component';
import {MatDialogModule} from "@angular/material/dialog";
import { RegisterComponent } from './components/dialogs/register/register.component';
import { ChangePasswordComponent } from './components/dialogs/change-password/change-password.component';
import { MapComponent } from './components/dialogs/map/map.component';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { SocialComponent } from './components/social/social.component';
import { PromotionContentComponent } from './components/promotions/promotion-content/promotion-content.component';
import { NotificationsComponent } from './components/dialogs/notifications/notifications.component';
import { NotificationComponent } from './components/dialogs/notifications/notification/notification.component';
import { PaymentsComponent } from './components/dialogs/payments/payments.component';
import { DonationCenterMapComponent } from './components/dialogs/donation-center-map/donation-center-map.component';
import { SettingsComponent } from './components/dialogs/settings/settings.component';
import { LoadingComponent } from './components/loading/loading/loading.component';
import { OnBoardingComponent } from './components/on-boarding/on-boarding/on-boarding.component';
import { LookupComponent } from './components/lookup/lookup.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {LookupPreferencesComponent} from './components/preferences/lookup-preferences.component';
import { PreferenceComponent } from './components/preferences/preference/preference.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PreferencesComponent,
    EulaComponent,
    ContactUsComponent,
    LoginComponent,
    RegisterComponent,
    ChangePasswordComponent,
    MapComponent,
    LeaderboardComponent,
    PromotionsComponent,
    SocialComponent,
    PromotionContentComponent,
    NotificationsComponent,
    NotificationComponent,
    PaymentsComponent,
    DonationCenterMapComponent,
    SettingsComponent,
    LoadingComponent,
    OnBoardingComponent,
    LookupComponent,
    LookupPreferencesComponent,
    PreferenceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    MatInputModule,
    FormsModule,
    MatBadgeModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatDialogModule,
    HammerModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatNativeDateModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
