import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-promotion-content',
  templateUrl: './promotion-content.component.html',
  styleUrls: ['./promotion-content.component.scss']
})
export class PromotionContentComponent implements OnInit {
  @Input()
  promotion: {
    title: string,
    subtitle: string,
    date: string,
    contentTitle: string,
    content: string,
    backgroundColor: string,
    dateColor: string
  }

  constructor() { }

  ngOnInit(): void {
  }

}
