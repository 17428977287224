<div class="on-boarding-container" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()">
  <h1>{{ model.title }}</h1>
  <mat-icon>{{ model.img }}</mat-icon>
  <p>{{ model.description }}</p>
  <div class="btn-container">
    <div class="dots-container">
      <span [ngStyle]="{'background-color': onBoardingOrder === 0 ? 'var(--buttonBlueColor)' : 'var(--darkColorGrey)'}"
            class="dot"></span>
      <span [ngStyle]="{'background-color': onBoardingOrder === 1 ? 'var(--buttonBlueColor)' : 'var(--darkColorGrey)'}"
            class="dot"></span>
      <span [ngStyle]="{'background-color': onBoardingOrder === 2 ? 'var(--buttonBlueColor)' : 'var(--darkColorGrey)'}"
            class="dot"></span>
      <span [ngStyle]="{'background-color': onBoardingOrder === 3 ? 'var(--buttonBlueColor)' : 'var(--darkColorGrey)'}"
            class="dot"></span>
    </div>
    <button color="primary"
            mat-raised-button
            (click)="allowPermission(model.permission)"
            *ngIf="model.button">{{ model.button }}</button>
    <span *ngIf="model.permission" (click)="skip(model.permission)">{{ model.permission }}</span>
  </div>
</div>
<div>
  <div class="click-container" style="left: 0" (click)="clicked('left')"></div>
  <div class="click-container" style="right: 0" (click)="clicked('right')"></div>
</div>

