<div class="social-container">
  <div>
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FOctapharmaPlasma&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
      width="375"
      height="700"
      style="border:none;
    overflow:hidden"
      allowfullscreen="allowfullscreen"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;web-share">
    </iframe>
  </div>
</div>
