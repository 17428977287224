import {Component, Input, OnInit} from '@angular/core';
import {PreferenceModel} from '../../../models/preference.model';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})
export class PreferenceComponent implements OnInit {
  @Input()
  preference: PreferenceModel;

  constructor() { }

  ngOnInit(): void {
  }

}
