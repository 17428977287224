import { Component, OnInit } from '@angular/core';
import {PreferenceModel} from '../../models/preference.model';
import {Router} from '@angular/router';
import {AppStateService} from '../../app-state.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './lookup-preferences.component.html',
  styleUrls: ['./lookup-preferences.component.scss']
})
export class LookupPreferencesComponent implements OnInit {
  preferences: PreferenceModel[] = [
    {
      title: 'Yes, I\'d like email from Octopharma',
      description: 'Know about donation offers, promotions and initiatives.'
    },
  /*    {
        title: 'Yes, Id like messages from Octopharma',
        description: 'Know about donation offers, promotions and initiatives.'
      },*/
/*    {
      title: 'Yes, Id like to use Biometrics Unlock',
      description: 'Use Biometrics Unlock to Sign In, know donations, transfers, referral and more.'
    },*/
    {
      title: 'Accept terms of use',
      description: 'By joining, I agree to Octopharma Application Terms, Loyalty Terms and Referral Tersm'
    },
  ];

  constructor(private router: Router, private appState: AppStateService) { }

  ngOnInit(): void {
  }

  register() {
    this.router.navigate(['leaderboard']);
    this.appState.isLoggedIn = true;
  }
}
