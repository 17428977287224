import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {
  promotions = [
    {
      title: 'Make Over',
      subtitle: '$700 in December',
      date: 'Dec 01, 2020 - Dec 31, 2020',
      contentTitle: 'Maximize your holiday earnings.',
      content: 'Multiply bonuses when you donate every 3 days.(up to 8 times in 30 days)',
      backgroundColor: '#004F20',
      dateColor: '#3E8A3C'
    },
    {
      title: 'COVID donor bonus',
      subtitle: '$25 bonus per donation',
      date: 'Dec 01, 2020 - Dec 31, 2020',
      contentTitle: 'Your plasma can help others.',
      content: 'Must have tested positive for COVID-19 and have been symptom-free for 14 days.',
      backgroundColor: '#004F20',
      dateColor: '#3E8A3C'
    },
    {
      title: 'Collect up to',
      subtitle: '2,500 bonus points',
      date: 'Jan 18, 2021 - Jan 22, 2021',
      contentTitle: 'Exclusive offer for early birds.',
      content: 'Multiply your bonuses when you donate every 3 days (up to 8 times in 30 days).',
      backgroundColor: '#011F3B',
      dateColor: '#3A4D8F'
    },
    {
      title: 'Valentine s Day gift',
      subtitle: '500 bonus',
      date: 'Feb 14, 2021',
      contentTitle: 'Come in for your Valentine\'s Day gift',
      content: 'We appreciate your loyalty.',
      backgroundColor: '#D62755',
      dateColor: '#FF7595'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
