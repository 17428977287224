<div class="register-container">
  <h1>Sign up</h1>
  <mat-form-field appearance="outline">
    <mat-label>First Name</mat-label>
    <input
      matInput
      ngModel
      required
      #name="ngModel"
      type="text"
      placeholder="First Name">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input
      matInput
      ngModel
      required
      #email="ngModel"
      type="email"
      placeholder="Email">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input
      matInput
      ngModel
      #password="ngModel"
      type="password"
      placeholder="Password"
      required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Repeat Password</mat-label>
    <input
      matInput
      ngModel
      #repeatPassword="ngModel"
      type="password"
      placeholder="Repeat Password"
      required>
  </mat-form-field>
  <p>By signing up, I agree to the Privacy Policy and the Terms of Services.</p>
  <button mat-raised-button
          color="primary"
          mat-dialog-close
          [disabled]="name.invalid || password.invalid || repeatPassword.invalid || email.invalid">SIGN UP</button>
  <button mat-stroked-button
          (click)="openLoginDialog()"
          color="primary"
          class="mat-stroked-button"
          mat-dialog-close>OR LOGIN</button>
</div>
