<div class="eula-container">
  <h1>End-User License Agreement</h1>
  <p>
    This website is created and controlled by Octapharma Plasma, Inc. (“Octapharma Plasma”).
    By using this website you agree to abide by the terms and conditions set out below (“Terms”).
    If you do not agree to abide by these Terms do not use this website.
  </p>
  <h2>Intellectual Property Rights</h2>
  <p>
    This website and all pages and materials related therein, including but not limited to information, text, images,
    photos, illustrations, trademarks, designs, icons, photographs, videos messages, advertisements, and other content
    or materials that appear as part of this website (collectively, the "Content") are subject to copyright, trademark,
    trade dress and/or other intellectual property rights owned, controlled, licensed or used with permission by
    Octapharma Plasma. Except as specifically outlined below, nothing contained herein shall be construed as conferring
    by implication or otherwise any license or right under any patent, trademark or copyright of Octapharma Plasma or
    any third party. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly
    perform, republish, download, store or transmit any of the Content except for your own personal, non-commercial use.
  </p>
  <p>
    Octapharma Plasma holds all intellectual property rights in relation to all products and or services referred to on
    this website, unless otherwise indicated to the contrary. No representations should be made in relation to the
    manufacture, sale or distribution of Octapharma Plasma products or services except with the prior written consent of
    Octapharma Plasma.
  </p>
  <h2>Copyright Protection</h2>
  <p>Copyright in this website and all Content contained on this website is owned by Octapharma Plasma, unless otherwise
    indicated. You agree to abide by any and all copyright notices, information, or restrictions contained in or
    relating to this website or any Content.</p>
  <p>Octapharma Plasma reserves all rights in the content and design of this website. Unless permitted in writing by
    Octapharma Plasma, you may not reproduce, use or copy this website or any Content for any purpose other than for
    your personal, noncommercial use. You may not use commercially any information, products or services on this website
    and you may not cause any of the Content on this website to be framed or embedded into another website without
    written permission from Octapharma Plasma.</p>
  <h2>No Warranties or Representations</h2>
  <p>THIS WEBSITE AND THE CONTENT, PRODUCTS, SERVICES, FUNCTIONS, SOFTWARE, PROGRAMS, INFORMATION AND MATERIALS MADE
    AVAILABLE OR ACCESSED THROUGH THIS WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR
    IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
    NON-INFRINGEMENT.</p>
  <p>To the fullest extent permissible by law, Octapharma Plasma expressly disclaims all representations and warranties,
    express or implied, in connection with or in respect of this website, the Content or materials on this website,
    information and functions made accessible or accessed through this website, and/or the products or services referred
    to on it - including, without limitation any representations or warranties of any kind in relation to:</p>
  <ul>
    <li>the accuracy, reliability, security, quality, availability or completeness of this website or the Content
      contained in this website;
    </li>
    <li>the accuracy, reliability, security, quality or completeness of any information contained in any website
      hyperlinked to this website;
    </li>
    <li>the functions, software, third party applications, programs and/or devices contained in or accessed through this
      website, including without limitation that they will function uninterrupted or error free, that defects will be
      corrected, or that this website or the server that makes it available is free of viruses or other harmful
      components;
    </li>
    <li>the therapeutic or other qualities of any products or services manufactured, supplied or distributed by
      Octapharma Plasma;
    </li>
    <li>any performance results specified in this website in respect of any products manufactured, supplied or
      distributed by Octapharma Plasma or its related entities.
    </li>
  </ul>
  <p>The Content on this website is provided on the basis that all persons accessing the website undertake the
    responsibility for assessing the accuracy and completeness of the Content and that they rely on it entirely at their
    own risk. Octapharma Plasma (including its directors, affiliates, officers, employees, agents, contractors,
    successors and assigns) does not accept any liability for loss or damages incurred by any person as a result of
    reliance placed upon the Content of this website or any other information incorporated by reference.</p>
  <p>Octapharma Plasma is providing the “Google Translate” option to assist you in reading the website in languages
    other than English. Google Translate cannot translate all types of documents, and may not provide an exact
    translation. Anyone relying on information obtained from Google Translate does so at his or her own risk. Octapharma
    Plasma does not make any promises, assurances, or guarantees as to the accuracy of the translations provided.
    OCTAPHARMA PLASMA, ITS OFFICERS, EMPLOYEES, AND/OR AGENTS SHALL NOT BE LIABLE FOR DAMAGES OR LOSSES OF ANY KIND
    ARISING OUT OF, OR IN CONNECTION WITH, THE USE OR PERFORMANCE OF SUCH INFORMATION, INCLUDING BUT NOT LIMITED TO,
    DAMAGES OR LOSSES CAUSED BY RELIANCE UPON THE ACCURACY OF ANY SUCH INFORMATION, OR DAMAGES INCURRED FROM THE
    VIEWING, DISTRIBUTING, OR COPYING OF SUCH MATERIALS.</p>
  <h2>User Content</h2>
  <p>These Terms apply to each visitor to and user of this website, including unregistered and registered users
    (individually "User" and collectively, "Users"). Octapharma Plasma has no duty to pre-screen content posted on the
    website by Users, whether directly contributed by the User or contributed by us or a third party on behalf of the
    User, including, without limitation, postings, messages, testimonials, text, files, images, photos, forum or blog or
    any other content provided by a User to this website ("User Content").</p>
  <p>All User Content is the sole responsibility of the User who contributed such content, whether such User contributed
    the content directly or through a third party website. You understand that Octapharma Plasma does not control, and
    is not responsible for User Content made available through this website and specifically disclaims any liability
    related thereto. All Users represent and warrant that they own or otherwise control and have all legal rights to the
    content submitted by them and the name or other identifier used in connection with such submission including, but
    not limited to, all the rights necessary to provide, post, upload, input or submit the User Content.</p>
  <p>Octapharma Plasma further reserves the right to decline to permit the posting on this website or to remove from
    this website, any User Content that violates the Terms, any applicable law or regulation or any guidelines posted on
    this website, each as determined in Octapharma Plasma’s sole discretion. Finally, Octapharma Plasma reserves the
    right (but does not assume the obligation) to edit User Content in a non-substantive manner solely to cause the
    content to comply with our content guidelines or formatting requirements.</p>
  <p>User Content shall not: (a) infringe anyone’s rights, violate the law or otherwise be inappropriate; (b) include
    personal information that can be used to identify or contact any person without that person’s explicit consent; (c)
    include promotional content that would promote other websites, businesses, services or products unaffiliated with
    the website without our express permission; (d) be unlawful, harmful, threatening, abusive, harassing, defamatory,
    libelous, or invasive of another’s privacy; (e) be obscene, abusive, discriminatory or illegal; (f) be false or
    misleading; (g) contain any viruses, cancel bots, Trojan horses, harmful code, or other computer software or program
    designed to interrupt the website or the proper functioning of any software, hardware, equipment or materials used
    in connection with this website; or (h) be bulk unsolicited advertising, promotional information, email, or other
    solicitation, including without limitation junk mail, “spam,” chain letters or pyramid schemes of any sort.</p>
  <p>Although Octapharma Plasma does not claim ownership of User Content, you grant Octapharma Plasma and its affiliates
    and related entities an irrevocable, perpetual, non-exclusive right to use, copy, perform, display and distribute
    such User Content and to prepare derivative works of, or incorporate into other works, such User Content, and to
    grant and authorize sublicenses (through multiple tiers) of the foregoing. You also grant Octapharma Plasma the
    ability to copyright and protect the User Content, including the images, copy, and content, from the unauthorized
    use by unaffiliated third parties who may, from time to time, attempt to pirate such information via electronic or
    other means. This includes, but is not limited to, the right to file suit to seek injunctive relief to protect such
    material. You further agree to assist Octapharma Plasma, at our expense and control, to protect such copyrighted
    material from unauthorized redistribution.Ł</p>
  <h2>Limitation of Liability</h2>
  <p>In no event shall Octapharma Plasma (including its directors, affiliates, officers, employees, agents, contractors,
    successors and assigns) be liable for any direct, indirect, punitive, incidental, special, consequential damages or
    any damages whatsoever including, without limitation, damages for loss of use, data, profit earnings, or lost
    opportunities, arising out of or in any way connected with (i) this website, (ii) these Terms, (iii) any breach of
    these Terms by you or a third party, (iv) the use or performance of our products or services, (v) any User Content
    and/or (vi) interaction between this website and any third party website, including without limitation social media
    sites. These limitations and exclusions apply without regard to whether the damages are based on contract, tort,
    negligence, strict liability or otherwise even if Octapharma Plasma has been advised of the possibility of
    damages.</p>
  <p>Where liability cannot be excluded, any liability incurred by us in relation to the use of this website or the
    content is limited as provided under s 68A of the Trade Practices Act 1974 (Cth).</p>
  <h2>Governing Law and Jurisdiction</h2>
  <p>All matters relating to this website and these Terms and any dispute or claim arising therefrom or related thereto
    (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with
    the internal laws of the State of North Carolina without giving effect to any choice or conflict of law provision or
    rule (whether of the State of North Carolina or any other jurisdiction).</p>
  <h2>Limitation on Time to File Claims</h2>
  <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THIS WEBSITE MUST BE
    COMMENCED IN NORTH CAROLINA WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION
    OR CLAIM IS PERMANENTLY BARRED.</p>
  <h2>Third Party Websites and Applications</h2>
  <p>As a convenience to you, Octapharma Plasma may provide, on this website, links to websites and applications
    operated by other entities (“Linked Sites”). If you use these Linked Sites, you will leave this website. If you
    decide to visit any Linked Site, you do so at your own risk and it is your responsibility to take all protective
    measures to guard against viruses or other destructive elements. If you link to, or are redirected to a Linked Site,
    you should read that Linked Site’s privacy policy before disclosing any personal information.</p>
  <p>This website may provide certain social media features that enable you to link from your own or certain third-party
    websites to certain content on the website, send e-mails or other communications with certain content, or links to
    certain content, on this website, or cause limited portions of content on this website to be displayed or appear to
    be displayed on your own or certain third-party websites. You may use these features solely as they are provided by
    Octapharma Plasma and otherwise in accordance with any additional terms and conditions we provide with respect to
    such features.</p>
  <p>Octapharma Plasma (including its directors, affiliates, officers, employees, agents, contractors, successors and
    assigns) makes no warranty or representation regarding, and does not endorse, any linked websites or the information
    appearing thereon or any of the products or services described thereon.</p>
  <p>Links do not imply that Octapharma Plasma is affiliated or associated with, or is legally authorized to use any
    trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any Linked
    Site is authorized to use any trademark, trade name, logo or copyright symbol of Octapharma Plasma.</p>
  <p>All links to this website must be approved by Octapharma Plasma. Octapharma Plasma reserves the right to revoke
    this consent to link at any time in its sole discretion.</p>
  <h2>Social Media Websites</h2>
  <p>If this website offers a product or service which allows you to access or use any profile or other information
    about you that you have provided to Facebook or another third party website (each a " Social Media Site ") and you
    decide to use such a product or service, you acknowledge and agree that: (i) the information or content that are a
    part of your Social Media Site profile, which you have designated as "public" (or a similar designation) (with such
    information or content and referred to herein as "Social Media Content") may be accessed and used by us in
    connection with the Site; (ii) the Social Media Content will be considered "User Content" under these Terms and both
    you and we shall have the same rights and responsibilities as you and we have with respect to User Content under
    these Terms; (iii) in the event that the Social Media Content was for any reason misclassified with a public or
    similar designation or is otherwise inaccurate or to which you do not agree with for any reason, you agree to work
    with the Social Media Site to make any changes or resolve any disputes and acknowledge that we will not be able to
    provide you with recourse; and (iv) the operation of your profile and account with and on the Social Media Site
    shall continue to be governed by the terms and conditions and privacy policy of such Social Media Site.</p>
  <h2>Medical and Health Information</h2>
  <p>This website may contain information on medical and health-related topics. All information provided on this
    website, or through links to other third party websites, is for informational purposes only and is not a substitute
    for obtaining proper medical, legal or other professional care or services. You should never take any action based
    upon any information contained on this website without seeking independent professional advice from a person who is
    licensed and/or knowledgeable in the applicable field. Furthermore, you should not use this information for
    diagnosing a health problem or disease but should always consult your own physician.</p>
  <h2>Geographic Restrictions</h2>
  <p>Octapharma Plasma is based in the United States and provides this website for use only by persons located in the
    United States. Octapharma Plasma makes no claims that this website or any of its Content, information, products or
    services on or accessed through this website are authorized, available, accessible or appropriate outside of the
    United States. Access to this website may not be legal by certain persons or in certain countries. If you access
    this website from outside the United States, you do so on our own initiative and you are responsible for compliance
    with local laws.</p>
  <h2>Privacy Protection</h2>
  <p>All information we collect on this website is subject to our Privacy Policy at
    https://www.octapharmaplasma.com/privacy_statement/. By using this website, you accept and agree to be bound and
    abide by our Privacy Policy. If you do not want to agree to our Privacy Policy, you must not provide us any personal
    information.</p>
  <h2>Amendments to this website and Terms</h2>
  <p>Octapharma Plasma reserves the right to make changes, modifications, additions, deletions and corrections to this
    website, and any service or material provided on the website, at any time and without notice. Octapharma Plasma may
    also withdraw access to this website at any time without notice. From time to time, we may restrict access to some
    parts of this website, or the entire website, to Users.</p>
  <p>For further information contact us at +1 704-654-4600.</p>
</div>
