import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  firstLabel = false;
  secondLabel = false;
  money = 0;

  sliderChange() {
    if (this.money >= 3) {
      this.firstLabel = true;
    }

    if (this.money >= 8) {
      this.secondLabel = true;
    }

    if (this.money < 3) {
      this.firstLabel = false;
    }

    if (this.money < 8) {
      this.secondLabel = false;
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
