import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {PreferencesComponent} from "./components/drawer-components/preferences/preferences.component";
import {EulaComponent} from "./components/drawer-components/eula/eula.component";
import {ContactUsComponent} from "./components/drawer-components/contact-us/contact-us.component";
import {LeaderboardComponent} from "./components/leaderboard/leaderboard.component";
import {PromotionsComponent} from "./components/promotions/promotions.component";
import {SocialComponent} from "./components/social/social.component";
import {LookupComponent} from './components/lookup/lookup.component';
import {LookupPreferencesComponent} from './components/preferences/lookup-preferences.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'preferences', component: PreferencesComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'promotions', component: PromotionsComponent },
  { path: 'lookup', component: LookupComponent },
  { path: 'lookup-preferences', component: LookupPreferencesComponent },
  { path: 'social', component: SocialComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
