import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {LoginComponent} from "../dialogs/login/login.component";
import {MapComponent} from "../dialogs/map/map.component";
import {AppStateService} from "../../app-state.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  firstLabel = false;
  secondLabel = false;
  money = 0;

  constructor(private dialog: MatDialog, public appState: AppStateService) { }

  ngOnInit(): void {
  }

  sliderChange() {
    if (this.money >= 3) {
      this.firstLabel = true;
    }

    if (this.money >= 8) {
      this.secondLabel = true;
    }

    if (this.money < 3) {
      this.firstLabel = false;
    }

    if (this.money < 8) {
      this.secondLabel = false;
    }
  }

  openLoginDialog() {
    this.dialog.open(LoginComponent, { minWidth: 340, backdropClass: 'dialog-backdrop' });
  }

  openMapDialog(value: string) {
    this.dialog.open(MapComponent, { minWidth: 340, data: value, backdropClass: 'dialog-backdrop' })
  }
}
