<div class="change-password-container">
  <h1>Reset Password</h1>
  <p>Enter the email address associated with your account, and we'll email you a link to reset your password.</p>
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input
      matInput
      ngModel
      #email="ngModel"
      type="email"
      placeholder="Email"
      required>
  </mat-form-field>
  <button mat-raised-button color="primary">SEND RESET LINK</button>
</div>
