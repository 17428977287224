import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';

const isAlreadyOnBoardKey = 'isAlreadyOnBoard'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Output()
  loading: EventEmitter<boolean>

  constructor(private router: Router) {
    this.loading = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
/*    interval(5000)
      .pipe(
        first()
      )
      .subscribe(() => this.loading.emit(false))*/
  }

  redirectToPage() {
    this.loading.emit(false);
  }
}
