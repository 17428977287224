<div class="settings-container">
  <h1>My goal</h1>
  <span>Potential earnings</span>
  <p>{{ '$' + money * 35 }}</p>

  <div class="slider">
    <div class="slider-label">
      <span style="visibility: hidden">0</span>
      <span [style.font-weight]="money == 1 ? 'bold' : ''">1</span>
      <span [style.font-weight]="money == 2 ? 'bold' : ''">2</span>
      <div class="slider-label-img">
        <span [style.font-weight]="money == 3 ? 'bold' : ''">3</span>
        <mat-icon *ngIf="firstLabel">wb_sunny</mat-icon>
        <span
          class="slider-bonus"
          *ngIf="firstLabel">Bonus!</span>
      </div>
      <span [style.font-weight]="money == 4 ? 'bold' : ''">4</span>
      <span [style.font-weight]="money == 5 ? 'bold' : ''">5</span>
      <span [style.font-weight]="money == 6 ? 'bold' : ''">6</span>
      <span [style.font-weight]="money == 7 ? 'bold' : ''">7</span>
      <div class="slider-label-img">
        <span [style.font-weight]="money == 8 ? 'bold' : ''">8</span>
        <mat-icon *ngIf="secondLabel">wb_sunny</mat-icon>
        <span
          class="slider-bonus"
          *ngIf="secondLabel">Bonus!</span>
      </div>
    </div>
    <input class="slider-range" value="0" type="range" max="8" min="0" step="1" [(ngModel)]="money"
           (ngModelChange)="sliderChange()">
  </div>

  <span>How many donations per month?</span>
  <button mat-raised-button [mat-dialog-close]="money">Save</button>
</div>
